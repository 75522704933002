:root {
  --animation-duration: 20s;
}

html {
  writing-mode: vertical-lr;
  color: #011627;
  background-color: #011627;
  overflow: hidden;
  background: linear-gradient(to right, #6BAC91, #8A4D93);
}

body {
  width: 2176px;
  height: 832px;
  box-sizing: border-box;
  padding: 5px 0;
  background: linear-gradient(to right, #6BAC91, #8A4D93);
}

ul {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 12vh;
  grid-auto-flow: dense;
  box-sizing: border-box;
}

li {
  display: block;
  overflow: hidden;
  writing-mode: horizontal-tb;
  background-color: aliceblue;
  box-sizing: border-box;
}

h1 {
  font-family: 'Modak', cursive;
  font-size: 16vh;
  margin-top: 3vh;
}

.span2 {
  grid-row-end: span 2;
}

.span3 {
  grid-row-end: span 3;
}

.span4 {
  grid-row-end: span 4;
}

.span5 {
  grid-row-end: span 5;
}

.span6 {
  grid-row-end: span 6;
}

.height2 {
  grid-column-end: span 2;
}

@media (max-height:500px) {
  ul {
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 18vh;
  }

  h1 {
    font-size: 21vh;
    margin-top: 4vh;
  }

  p {
    font-size: 4.5vh;
  }
}

@media (max-height:400px) {
  ul {
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 22vh;
  }

  h1 {
    font-size: 25vh;
    margin-top: 5vh;
  }

  p {
    font-size: 5.7vh;
  }
}

#origen-logo {
  width: 100px;
  height: 100px;
  object-fit: fill;
  position: fixed;
  bottom: 64px;
  right: 64px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 1); */
  background-image: url('/public/images/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: drop-shadow(10px 10px 10px #000);
}

.modal {
  display: flex;
  overflow: hidden;
}

.modal .carrousel-item-content {
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  background: #fff;
  height: 10vh;
  border-radius: 12px;
}

.modal .image-container {
  height: 120vh;
  width: 80vh;
}

.modal .image-prompt {
  writing-mode: horizontal-tb;
  box-sizing: border-box;
  padding: 6px;
  flex: 0 0 60px;
  width: 80vh;
  height: 20vh;
  line-height: 8.5px;
  overflow: hidden;
  font-size: 14px;
  font-family: Segoe UI;
  font-weight: 600;
  font-style: italic;
  text-overflow: ellipsis;
  text-align: center;
  color: #363636;
  background-image: url(/public/images/quotes.svg);
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 36px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: auto;
}

.modal .image-prompt div {
  width: 80vh;
  text-align: center;
}

.modal img {
  vertical-align: bottom;
  width: 100%;
  object-fit: contain;
}

.scroll {
  box-sizing: border-box;
  display: flex; /* Añade esta línea */
  overflow: hidden;
  flex-flow: column nowrap;
  gap: 10px;
}

.scroll ul {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  animation: scroll var(--animation-duration) linear infinite;
  /* animation-delay: calc(var(--animation-duration)*-1); */
}

.scroll ul:nth-child(2) {
  animation: scroll2 var(--animation-duration) linear infinite;
  /* animation-delay: calc(var(--animation-duration)/-2); */
}

.scroll .carousel-item .carousel-item-content {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.scroll li .image-prompt {
  box-sizing: border-box;
  padding: 6px;
  flex: 0 0 60px;
  height: 60px;
  width: 100%;
  line-height: 8.5px;
  overflow: hidden;
  font-size: 8.5px;
  font-family: Segoe UI;
  font-weight: 600;
  font-style: italic;
  text-overflow: ellipsis;
  text-align: center;
  color: #363636;
  background-image: url(/public/images/quotes.svg);
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 36px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: auto;
}

.scroll li .image-prompt div {
  width: 100%;
  text-align: center;
}

.scroll li img {
  vertical-align: bottom;
  width: 100%;
  object-fit: contain;
}

@keyframes scroll {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@keyframes scroll2 {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
} 

/* 
@keyframes scroll {
  0% {
    transform: translateX(101.3%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0.65%);
  }

  100% {
    transform: translateX(-200%);
  }
} */


/* @keyframes scroll {
  0% {
      transform: translateX(101.3%);
  }

  100% {
      transform: translateX(0%);
  }
}

@keyframes scroll2 {
  0% {
      transform: translateX(0.65%);
  }

  100% {
      transform: translateX(-100%);
  }
} */